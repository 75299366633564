$input-width: 70px;
$input-width-small: 55px;

.cfr {
	&-calc {
		.table {
			font-size: $font-size-base - 1;
			table-layout: fixed;

			td,
			th {
				border-top: 2px solid #fff !important;
				border-bottom: 2px solid #fff !important;
				padding: 6px 5px 5px !important;
			}

			&-striped {
				tr:nth-of-type(odd) {
					background: none;
				}

				tr:nth-of-type(even) {
					background: $table-bg-accent;
				}
			}

			td {
				&:not(:first-child) {
					text-align: right;
					width: $input-width-small;
				}
			}
			th {
				&:not(:first-child) {
					width: $input-width-small;
				}
			}

			tr.lighted,
			.lighted {
				background: lighten($color-green, 40%);
				font-weight: 400;
			}
			tr.lighted-light,
			.lighted-light {
				background: lighten($color-green, 50%);
				font-weight: 400;
			}
			tr.nobg {
				background: none;
			}

			strong {
				font-weight: 400;
			}
		}

		.form-control {
			height: 28px;
			margin: -6px -5px -5px -3px;
			font-size: $font-size-base - 1;
			padding: 6px 5px;
			line-height: 16px;
			box-shadow: none;
			border: 0;
			background: $color-yellow;
			color: #fff;
			width: $input-width-small - 2;
		}

		input {
			text-align: right;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				/* autoprefixer: off */
				-webkit-appearance: none;
			}
			& {
				/* autoprefixer: off */
				-moz-appearance: textfield;
			}
		}
	}

	&-specs {
		margin: 0;
	}

	&-whole {
		thead {
			th:nth-child(n + 3) {
				display: none;
			}
		}
	}

	&-headline {
		margin: 0 0 25px;

		h2 {
			display: inline-block;
			margin: 0;
			vertical-align: bottom;
		}
	}

	&-formulas {
		padding: 0;
		font-size: 0;
		margin: 0 0 0 10px;
		display: inline-block;
		vertical-align: bottom;

		li {
			display: inline-block;
			margin-left: 5px;

			&:before {
				display: none;
			}
		}

		.btn {
			width: 36px;
			height: 36px;
			background: $color-gray;
			padding: 8px 2px 12px;
			text-align: center;
			border: 0;
			color: #fff;
			box-shadow: none;

			&:hover,
			&:focus,
			.active {
				background: $color-gray-dark;
			}
		}
	}

	&-disclaimer {
		font-size: .9em;
		color: $color-gray;
		margin-top: 45px;
	}
}

@media (min-width: $screen-phone-min) {
	.cfr {
		&-calc {
			.table {
				td,
				th {
					&:not(:first-child) {
						width: $input-width;
					}
				}
			}

			.form-control {
				width: $input-width - 2;
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.cfr {
		&-whole {
			thead {
				th:nth-child(n + 3) {
					display: table-cell;
				}
			}

			.ng-hide {
				display: table-cell !important;
			}
		}

		&-select {
			display: none;
		}
	}
}

@media print {
  .cfr {
    &-calc {
      .table {
        td,
        th {
          background: none !important;
        }
        &-striped {
         tr:nth-of-type(even) {
            background: $table-bg-accent !important;
            -webkit-print-color-adjust: exact;
          }
        }
        tr.lighted,
        .lighted {
          background: lighten($color-green, 40%) !important;
          -webkit-print-color-adjust: exact;
        }
        tr.lighted-light,
        .lighted-light {
          background: lighten($color-green, 50%) !important;
          -webkit-print-color-adjust: exact;
        }
        tr.nobg {
          background: none !important;
        }
      }
      .form-control {
        background: $color-yellow !important;
        -webkit-print-color-adjust: exact;
        color: #fff !important;
      }
    }
    &-formulas {
      .btn {
        background: $color-gray !important;
        -webkit-print-color-adjust: exact;
        color: #fff !important;

        .active {
          background: $color-gray-dark !important;
        }
      }
    }
  }
}
