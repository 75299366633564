// ============================================================================
// Inner Page Layout
// ============================================================================

.article-summary {
  margin-bottom: 20px;
  .img-responsive {
    @extend .img-centered;
    @extend .img-restricted;
  }
}