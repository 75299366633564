// ============================================================================
// Search
// ============================================================================

.inline-search {
    padding: 10px 10px;

    .form-control {
        border-radius: 15px !important;
        height: 30px;
        background: $navbar-default-link-color;
        padding: 4px 24px 4px 12px;
        color: #fff !important;
        border: 0;

        &::-webkit-input-placeholder {
            font-style: italic;
            color: #fff;
        }
        &::-moz-placeholder {
            font-style: italic;
            color: #fff;
        }
        &:-ms-input-placeholder {
            font-style: italic;
            color: #fff;
        }
    }

    .btn {
        padding: 0;
        background: none !important;
        border: 0;
        margin-left: -20px !important;
        color: #fff;
        position: relative;
    }

    .input-group {
    	width: 100%;

    	&-btn {
    		width: auto;
    	}
    }
}

.block-search {

}
