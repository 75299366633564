.topcalf {
	h1 {
		margin-bottom: 30px;
	}

	&-products {
		margin-top: 90px;
		text-align: center;
	}

	&-item {
		width: 200px;
		display: inline-block;
		vertical-align: top;
		margin: 0 auto 90px;
		font-size: 12px;

		.product-title {
			text-align: left;
			font-size: 30px;
		}

		&-svg {
			margin: 20px 0;
			display: block;
			padding-bottom: 102.06185567%;
			position: relative;

			svg {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}

			&:after {
				display: none;
			}

			&:hover,
			&:focus {
				.s {
					fill: lighten(#b3b2b2, 3%);
				}

				@for $i from 1 through 6 {
				&.sector-#{$i} {
					.s-#{$i} {
						fill: lighten(#e30613, 3%) !important;

						.product-formula-1 & {
							fill: lighten($color-yellow, 3%) !important;
						}

						.product-formula-20 & {
							fill: lighten($color-green, 3%) !important;
						}
					}
				}
			}
			}
		}

		h6 {
			margin: 0 $grid-gutter-width / 2 15px;
			text-transform: uppercase;
		}

		p {
			margin: 0 $grid-gutter-width / 2;
		}
	}
}

@media (min-width: $screen-xs-min) {
	.topcalf {
		&-products {
			margin: 90px -20px 0;
		}
		&-item {
			margin: 0 20px 90px;
		}
	}
}

@media (min-width: $screen-sm-min) {
	.topcalf {
		&-products {
			margin: 70px -25px 0;
		}
		&-item {
			margin: 0 25px 90px;

			&-svg {
				margin: 45px 0 35px;
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.topcalf {
		&-item {
			width: 250px;
		}
	}
}
