// ============================================================================
// Hamburger Menu
// ============================================================================

.navbar-toggle {
  height: 32px;

  .icon-bar {
    position: relative;
    transition: all 500ms ease-in-out;

    &:nth-of-type(2) {
      top: 1px;
    }

    &:nth-of-type(3) {
      top: 2px;
    }
  }

  &.active {
    .icon-bar {
      &:nth-of-type(1) {
        transform: translateY(7px) rotate(45deg);
      }

      &:nth-of-type(2) {
        background-color: transparent;
      }

      &:nth-of-type(3) {
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }
}
