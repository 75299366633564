// ============================================================================
// Header
// ============================================================================

.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2147483641;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;

    .logo {
        text-indent: -9999px;
        background: url(../images/DNS-logo.png) no-repeat center;
        background-size: 100% 100%;
        width: 84px;
        height: 40px;
        display: block;
        padding: 0;
        margin: 18px 15px 0;

        img {
          display: none;
          max-width: 100%;
        }
    }
}

.navbar {
    &-default {
        border-width: 0 0 1px 0;
    }

    &-search {
        float: right;
        width: 160px;
        margin-bottom: 7px;

        .inline-search {
            padding: 18px 0 0;

            .form-control {
                border-radius: 10px !important;
                height: 20px;
            }
        }
    }

    &-collapse {
        clear: right;
        margin-right: -15px !important;
    }

    &-default &-toggle {
        border: 0;
        background: none !important;
        margin-top: 21px;
        padding: 9px 5px;
        margin-right: 10px;

        .icon-bar {
            background-color: $navbar-default-link-color;
        }
    }
}

@media (min-width: $screen-md-min) {
    .header {
      position: relative !important;
      width: auto !important;
      overflow: visible;
      max-height: none;

      .logo {
          margin-left: 0 !important;
      }
    }
}

@media (max-width: $screen-sm-max) {
  .header {
    .container {
      width: auto;
    }  }
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (   min--moz-device-pixel-ratio: 1.5),
        only screen and (     -o-min-device-pixel-ratio: 3/2),
        only screen and (        min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 192dpi) {
    .header .logo {
        background-image: url(../images/DNS-logo@2x.png);
    }
}
@media  only screen and (-webkit-min-device-pixel-ratio: 2.5),
        only screen and (   min--moz-device-pixel-ratio: 2.5),
        only screen and (     -o-min-device-pixel-ratio: 5/2),
        only screen and (        min-device-pixel-ratio: 2.5),
        only screen and (min-resolution: 288dpi) {
    .header .logo {
        background-image: url(../images/DNS-logo@3x.png);
    }
}

@media print {
  .navbar {
    display: block;

    &-search,
    & &-collapse,
    &-toggle {
      display: none !important;
    }
  }

  .header {
    position: relative;

    .logo {
      text-indent: 0;

      span {
        display: none;
      }

      img {
        display: block;
      }
    }
  }
}
