.topcow {
	background-color: $color-gray-dark;
	color: $color-text-white;

	h1 {
		margin-bottom: 60px;
	}

	&-svg {
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;

		svg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&.dark {
			.s {
				fill: #404243;

				&-w {
			   		&:hover {
			   			.s {
			   				fill: lighten(#404243, 10%);
			   			}
			   		}
			   	}
			}

			.t {
				fill: #797c7d;

				&-s {
					fill: #fff;
				}
				&-p {
					fill: #787576;
				}
			}

			.rb {
				fill: #141413;
			}
			.r {
				fill: #9d9d9c;
			}
		}

		@for $i from 1 through 6 {
			&.sector-#{$i} {
				.s-#{$i} {
					fill: #8f8f8f !important;
				}
				.rb-#{$i} {
					fill: #6f0611;
				}
				.r-#{$i} {
					fill: #ed1c24;
				}
				.s-w-#{$i} {
					.t {
						fill: #fff;
					}

					.p {
						fill: #ed1c24;
						stroke: #4c4c4b;

						&-w {
							&:hover {
								.p {
									fill: lighten(#ed1c24, 10%);
								}
							}
						}

						&-e {
							fill: #383838;
							stroke: #4c4c4b;
						}
					}
				}
			}
		}
	}

	&-menu {
		margin-bottom: 80px;

		&-item {
			margin-top: 1px;

			.btn {
				background: #404243;
				border-color: #fff;
				color: #fff !important;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 400;
				padding: 10px;
				outline: none !important;
				width: 100%;
				border-width: 0;

				&:hover,
				&:focus {
					background: lighten(#404243, 10%);
				}

				&:active {
					background: $brand-primary;
					box-shadow: none;
				}
			}

			&.open .btn {
				background: $brand-primary !important;
				border-color: $brand-primary !important;
			}

      &-shift {
        position: relative;
        top: 20px;
      }
		}

		.dropdown {
			&-menu {
				font-weight: 400;
				font-size: 12px;
				border: 0;
				margin: 0;
				padding: 0;
				position: static;
				width: 100%;
				max-height: 0;
				overflow: hidden;
				transition: max-height $transition-duration-long ease;
				float: none;
				display: block;

				li {
					&:before {
						display: none;
					}
				}

				a {
					color: $text-color;
					padding: 6px $grid-gutter-width / 2;

					&:after {
						display: none;
					}
				}
			}

			&.open .dropdown-menu {
				max-height: 250px;
			}
		}

    .popover {
      font-size: $font-size-base - 2;
      left: -$grid-gutter-width !important;
      right: -$grid-gutter-width !important;
      top: 100% !important;
      border: 0;

      &-content {
        padding: 0;

        a {
          padding: 9px 10px;
          color: $text-color;

          &:after {
            display: inline-block !important;
            border: solid;
            width: 0 !important;
            border-width: 4px 0 4px 8px;
            border-color: transparent transparent transparent $brand-primary;
            position: static;
            margin-left: 5px;
          }
        }
      }
    }
	}

	.news-list {
		margin-top: 60px;

		h3 {
			color: #fff;
		}

		a {
			color: #fff;
			font-weight: 400;

			&:hover,
			&:focus {
				color: $brand-primary;
			}
		}

		li {
			margin-top: 4px;
		}
	}

	&-svg &-menu-item {
		float: right;
		position: relative;
		z-index: 5;
	}
}

@media (max-width: $screen-xs-max) {
  .topcow {
    &-menu {
      .popover {
        display: none !important;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
	.topcow {
		&-menu {
			display: flex;
			justify-content: space-between;

			&-item {
				margin: 0;

				.btn {
					width: auto;
					background: none;
					border-width: 1px !important;
					padding: 4px 10px;

					&:hover,
					&:focus {
						border-color: $brand-primary;
						background: none;
					}
				}
			}

			.dropdown {
				&-menu {
					display: none;
				}
			}
		}
	}
}

#powerTipProduct {
  cursor: default;
  background-color: rgba(#fff, 0.95);
  color: $text-color;
  display: none;
  padding: 5px 10px;
  font-size: 13px;
  position: absolute;
  white-space: normal !important;
  z-index: 2147483647;
  max-width: 220px;
  font-weight: 400;
}
#powerTipProduct:before {
  content: "";
  position: absolute;
}
