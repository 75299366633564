.calccomp {
  main {
    color: #4d4d4d;

    h1 {
      font-size: 32px;
      line-height: 1;
      font-weight: 700;

      .highlight {
        color: #3e973b;
      }
    }
  }

  &-info {
    margin-top: 30px;

    h3,
    h4 {
      padding-bottom: 7px;
      border-bottom: 1px solid $brand-primary;
      margin-bottom: 25px;
      text-transform: uppercase;
    }

    h4 {
      font-size: 16px;
      padding-bottom: 3px;
      color: $brand-primary;
    }

    h2 {
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
    }

    hr {
      border-color: $brand-primary;
    }
  }

  &-hint,
  &-feeding {
    text-transform: uppercase;
    line-height: 1.1;
    font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }

  &-number {
    font-size: 41px;
    line-height: 1.1;
    font-weight: 300;
  }

  &-actual &-number {
    color: #3e973b;
  }

  &-response {
    font-size: 30px;
  }

  &-box {
    margin-bottom: 25px;
  }

  &-plans {
    margin-top: 20px;
    padding: 30px 0 0;
    background: $color-gray-light;

    .col-sm-6 {
      text-align: center;
    }

    h1 {
      margin-bottom: 45px;
    }

    &-download {
      display: inline-block;
      margin: 0 0 30px;
      color: $text-color;
      fill: $text-color;

      svg,
      img {
        width: 73px;
        height: 72px;
        display: block;
        margin: 0 auto 25px;
      }

      strong {
        font-weight: 700;
      }
    }
  }

  &-performance {
    background: $color-gray-dark;
    color: $color-text-white;
    padding: 75px 0 40px;

    &-intro {
    }
  }

  .contact-team {
    margin: 60px 0 20px;
  }
}

@media (min-width: $screen-xs-min) {
  .calccomp {
  }
}

@media (min-width: $screen-sm-min) {
	.calccomp {
    main {
      font-size: 16px;

      h1 {
        font-size: 48px;
      }
    }

    &-info {
      margin-top: 45px;

      h3 {
        font-size: 24px;
        padding-bottom: 9px;
        margin-bottom: 32px;
      }

      h2 {
        font-size: 30px;
      }
    }

    &-plans {
      h1 {
        margin-bottom: 60px;
      }
    }

    &-performance {
      &-intro {
        font-size: 16px;
      }
    }
	}
}

@media (min-width: $screen-md-min) {
  .calccomp {
    main {
      font-size: 18px;

      h1 {
        font-size: 55px;
      }
    }

    &-info {
      margin-top: 60px;

      h3 {
        padding-bottom: 12px;
        font-size: 30px;
        margin-bottom: 38px;
      }

      h4 {
        font-size: 18px;
        margin-bottom: 30px;
      }

      hr {
        margin: 40px 0;
      }


    }

    &-hint,
    &-feeding {
      font-size: 16px;
    }

    &-number {
      font-size: 56px;
    }

    &-response {
      font-size: 40px;
    }

    &-box {
      margin-bottom: 35px;
    }

    &-plans {
      margin-top: 40px;
      padding: 60px 0 30px;

      h1 {
        margin-bottom: 80px;
      }
    }

    &-performance {
      &-intro {
        font-size: 17px;
      }
    }

    .contact-team {
      margin: 80px 0 30px;
    }
  }
}
