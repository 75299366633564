// ============================================================================
// Footer
// ============================================================================

.footer {
    background-color: #000;
    font-size: 0;
    color: $color-text-white;
    text-align: center;
    z-index: 5;

    ul {
        list-style: none;
        width: 100%;
        margin: 15px 0;
        padding: 0;
    }

    li {
        vertical-align: middle;
        display: inline-block;
        font-size: 12px;
        margin-right: 10px;

        &.footer-logo {
            display: block;
            margin: 0 0 10px;
        }
        &.footer-phone {
            display: block;
            margin: 10px 0 0;
        }
    }

    .logo {
        text-indent: -9999px;
        background: url(../images/logo-footer.png) no-repeat center;
        background-size: 100% 100%;
        width: 39px;
        height: 21px;
        display: block;
        margin: 0 auto;
    }

    a {
        color: $color-text-white;
        display: block;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }

    &-phone {
        display: block;
        margin-right: 0;

        a {
            margin-left: 5px;
            color: $brand-primary;
            display: inline;

            &:hover,
            &:focus {
                color: $color-text-white;
            }
        }
    }

    &-social {
      display: block;

      a {
        font-size: 20px;
        padding: 10px 0 !important;
        line-height: 20px !important;
      }

      &-facebook {
        &:hover,
        &:focus {
          color: #3b5998 !important;
        }

      }
    }
}
@media (min-width: $screen-sm-min) {
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        text-align: left;
        line-height: 40px;
        overflow: hidden;
        white-space: nowrap;

        ul {
            margin: 0;
        }

        li {
            &.footer-logo {
                display: inline-block;
                margin: 0 15px 0 0;
            }

            &.footer-phone {
                float: right;
                margin: 0;
                display: inline;
            }

            &.footer-social {
              float: right;
            }
        }

        a {
            line-height: 16px;
            padding: 12px 0;
        }

        &-phone {
            a {
                float: right;
                display: block;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .footer {
        li {
            margin-right: 15px;
        }
    }
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (   min--moz-device-pixel-ratio: 1.5),
        only screen and (     -o-min-device-pixel-ratio: 3/2),
        only screen and (        min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 192dpi) {
    .footer .logo {
        background-image: url(../images/logo-footer@2x.png);
    }
}
@media  only screen and (-webkit-min-device-pixel-ratio: 2.5),
        only screen and (   min--moz-device-pixel-ratio: 2.5),
        only screen and (     -o-min-device-pixel-ratio: 5/2),
        only screen and (        min-device-pixel-ratio: 2.5),
        only screen and (min-resolution: 288dpi) {
    .footer .logo {
        background-image: url(../images/logo-footer@3x.png);
    }
}

@media print {
  .footer {
    display: none;
  }
}
