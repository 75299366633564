// ============================================================================
// Home
// ============================================================================

.home {
    background-color: $color-gray-dark;
    color: $color-text-white;

    .navbar {
        &-default {
            border-width: 0;
        }
    }

    &-hero {
        padding: 40px 0 0;
        text-align: center;
        display: none;

        &-inner {
            display: inline-block;
            text-align: left;
        }

        &-title {
            font-size: 31px;
            line-height: 31px;
            text-transform: uppercase;
            font-weight: 400;
            margin: 0 0 25px;
        }

        img {
            margin: 0 0 -4px;
            width: 630px;
        }
    }

    &-intro {
        background: $color-gray-light;
        padding: 30px 0 25px;
        position: relative;
        z-index: 5;
        color: $gray-base;
        font-size: 17px;

        h2 {
            font-size: 30px;
            margin: 0;
            line-height: 1;
            color: $brand-primary;
            font-weight: 900;
            text-transform: uppercase;
        }

        h3 {
            font-size: 30px;
            line-height: 1;
            color: $gray-base;
            font-weight: 400;
            margin: 0 0 30px
        }

        &-video {
            margin: 30px 0;
        }

        &-videos {
          margin-top: 30px;
        }

        &-btns {
            .btn {
                display: block;
                text-transform: uppercase;
                padding: 10px 12px;
                margin-bottom: 5px;
            }
        }
    }

    &-more {
        position: relative;
        z-index: 4;
        padding: 30px 0;

        &-text {
            padding-bottom: 20px;
        }

        &-links {
            text-align: center;
            font-size: 12px;
            line-height: 13px;
            margin: 0 (-$grid-gutter-width / 2);

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                align-content: stretch;
            }

            li {
                min-height: 40px;
                display: flex;
                align-items: stretch;
                align-content: stretch;

                &:nth-child(odd) {
                    a {
                        color: #fff;
                        background: $color-gray;
                    }
                }
            }

            a {
                display: flex;
                flex: 1;
                align-items: center;
                color: $color-gray;
                background: #fff;
                padding: 2px 5px;

                &:hover,
                &:focus {
                    background: $brand-primary !important;
                    color: #fff;
                }

                span {
                    flex: 1;
                }
            }

            br {
                display: none;
            }
        }

        &-image {
            display: none;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;

            &:before {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 15%;
                width: 55%;
                height: 80%;
                background: url(../images/cow-big.png) no-repeat left top;
                background-size: cover;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .home {
        &-hero {
            display: block;
        }

        &-more {
            padding: 120px 0 132px;

            &-text {
                padding-bottom: 110px;
            }

            &-image {
                display: block;
            }

            &-links {
                position: absolute;
                bottom: -15px;
                left: 0;
                width: 100%;
                margin: 0;

                ul {
                    flex-direction: row;
                }

                li {
                    flex: 1 20%;
                }

                br {
                    display: inline;
                }

                &-arrow {
                    position: absolute;
                    top: -5px;
                    left: 0;
                    margin-left: -4px;
                    width: 0;
                    height: 0;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 8px solid #fff;
                    opacity: 0;
                    transition: all $transition-duration ease;
                }

                @for $i from 1 through 5 {
                    li:nth-child(#{$i}):hover ~ &-arrow {
                        opacity: 1;
                        left: 20% * $i - 10;
                    }
                }
            }
        }

        &-intro {
            padding: 50px 0 45px;

            h2,
            h3 {
                font-size: 38px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .home {
        &-swap {
            &-inner {
                padding: 31px 0 65px;
            }
        }

        &-more {
            &-image {
                &:before {
                    top: 0;
                    height: 120%;
                    width: 50%;
                }
            }
        }

        &-intro {
            &-video {
                margin-top: 0;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .home {
        &-hero {
            &-links {
                bottom: -12px;
            }
        }

        &-swap {
            &-inner {
                padding: 35px 0 69px;
            }
        }
    }
}

@media (min-width: 2000px) {
    .home {
        &-hero {
            &-swapped {
            }
        }
    }
}
