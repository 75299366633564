.makingitwork {
  main.container {
    padding-top: $grid-gutter-width;
  }

  &-hero {
    padding: 0;
    position: relative;
    width: 100%;
    max-width: $container-lg;

    &-copy {
      padding: $grid-gutter-width $grid-gutter-width / 2 0;

      h1 {
        color: $brand-primary;
        font-size: 32px;
        line-height: 1;
        margin: 0;
      }

      h2 {
        text-transform: uppercase;
        font-size: 22px;
        line-height: 1;
        margin: 0;
      }
    }
  }

  &-intro {
    font-size: 14px;
  }

  &-images {
    font-size: 0;
    text-align: center;
    overflow: hidden;
    padding-top: $grid-gutter-width;

    &-item {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  &-content {
    padding-top: $grid-gutter-width / 2;
    font-size: 14px;

    h1 {
      margin-bottom: $grid-gutter-width;
    }

    &-btns {
      margin: 26px 0;

      .btn {
        margin: 4px 5px 4px 0;
      }
    }
  }

  &-goal {
    max-width: 360px;
    margin: 39px auto 0;
    position: relative;
    font-size: 40px;
    line-height: 1;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -9px;
      left: 50%;
      width: 45px;
      height: 45px;
      background: url(../images/arrow-big.png) no-repeat center;
      background-size: 100% 100%;
      margin: 0 -22px;
      z-index: 5;
    }

    &-body {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $gray-base;
      border-radius: 50%;
      color: $color-text-white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-inner {
      width: 100%;
      text-align: center;
    }

    big {
      display: block;
      font-size: 124px;
      line-height: 100px;
      margin: 0 0 10px;
    }

    small {
      display: block;
      font-size: 22px;
    }
  }

  &-targets {
    padding-top: 45px;
    text-align: center;
    font-size: 0;

    h1 {
      color: $color-text-white;
      margin: 0 0 45px;
      text-align: left;
    }
  }

	.formulations-point {
		position: relative;
		margin-bottom: 25px;
    display: inline-block;
    float: none;

		&:before {
			content: '';
			display: block;
			padding-bottom: 100%;
		}

    span {
      font-size: 15px;
    }

		big {
			font-size: 50px;
		}
	}
}

@media (min-width: $screen-xs-min) {
  .makingitwork {
    &-images {
      margin: 0 (-$grid-gutter-width / 2);

      &-inner {
        margin: 0 -7px;
      }

      &-item {
        padding: 0 7px;
        width: 50%;
        display: inline-block;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
	.makingitwork {
    main.container {
      padding-top: 45px;
    }

    &-hero {
      &-copy {
        position: absolute;
        top: 16.144144%;
        left: 0;
        width: 100%;
        padding: 0 5.81196%;
      }

      h1 {
        font-size: 48px;
      }

      h2 {
        font-size: 32px;
      }
    }

		&-intro {
      padding: 20px 0 0;
      font-size: 18px;
    }

    &-images {
      padding-top: 65px;

      &-item {
        width: 25%;
      }
    }

    &-content {
      padding-top: 50px;
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 18px;

      &-col {
        float: none;
      }
    }

    &-goal {
      margin-top: 0;
    }

    &-targets {
      padding-top: 60px;
    }

    .formulations-point {
      margin-bottom: 55px;
    }
	}
}

@media (min-width: $screen-md-min) {
  .makingitwork {
    &-hero {
      &-copy {
        position: absolute;
        top: 24.144144%;
        left: 0;
        width: 100%;
        padding: 0 5.81196%;
      }

      h1 {
        font-size: 55px;
      }

      h2 {
        font-size: 40px;
      }
    }
  }
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (   min--moz-device-pixel-ratio: 1.5),
        only screen and (     -o-min-device-pixel-ratio: 3/2),
        only screen and (        min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 192dpi) {
    .makingitwork-goal:after {
        background-image: url(../images/arrow-big@2x.png);
    }
}
@media  only screen and (-webkit-min-device-pixel-ratio: 2.5),
        only screen and (   min--moz-device-pixel-ratio: 2.5),
        only screen and (     -o-min-device-pixel-ratio: 5/2),
        only screen and (        min-device-pixel-ratio: 2.5),
        only screen and (min-resolution: 288dpi) {
    .makingitwork-goal:after {
        background-image: url(../images/arrow-big@3x.png);
    }
}
