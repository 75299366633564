.people {
	background-color: $color-gray-dark;

	&-left {
		padding: 45px $grid-gutter-width / 2;
		background: #fff;
	}

	&-right {
		color: $color-text-white;
		font-size: 12px;
	}

	&-wrap {

	}

	&-map {
		position: relative;
		padding-bottom: 131.1%;

		svg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	&-list {
		margin-top: 20px;
		font-size: 12px;
	}
	&-item {
		padding: 15px;
		margin-right: -$grid-gutter-width / 2;
		background-color: transparent;
		transition: background-color $transition-duration ease;

		&-image {
			cursor: pointer;

			img {
				border-radius: 50%;
				margin-bottom: 0;
			}
		}

		.row {
			display: flex;
			align-items: center;
			margin-right: 0;
		}

		h5 {
			margin: 0 0 5px;
			font-weight: 400;
			font-size: 12px;
			cursor: pointer;
		}

		&-location {
			margin-bottom: 3px;
		}

		p {
			margin-bottom: 5px;
		}

		&-link {
			color: #fff;

			&:after {
				display: none;
			}

			.active & {
				color: #fff;
			}
		}

		&.active {
			background-color: $brand-primary;
			color: #fff;

			.btn {
				background: #fff;
				color: $brand-primary;
			}

			.fa {
				color: #fff;
			}
		}
	}

	.tooltip.top .tooltip-arrow {
		border-top-color: $color-gray-dark;
	}
	.tooltip-inner {
		background: $color-gray-dark;
	}
}

@media (min-width: $screen-sm-min) {
	.people {
		&-right {
			padding-top: 100px;
			padding-left: $container-sm / 12;
		}

		&-wrap {
			width: $container-sm / 2 * 5 / 6;
		}
		&-left &-wrap {
			float: right;
		}

		&-map {
			margin-right: $grid-gutter-width * 1.5;
		}

		&-flex {
			display: flex;
			align-items: stretch;
		}
	}
}

@media (min-width: $screen-md-min) {
	.people {
		&-right {
			padding-left: $container-md / 12;
		}

		&-wrap {
			width: $container-md / 2 * 5 / 6;
		}
	}
}

@media (min-width: $screen-lg-min) {
	.people {
		&-right {
			padding-left: $container-lg / 12;
		}

		&-wrap {
			width: $container-lg / 2 * 5 / 6;
		}
	}
}

/* PowerTip Plugin */
#powerTip {
	cursor: default;
	background-color: rgba($color-gray-dark, 0.95);
	color: #fff;
	display: none;
	padding: 5px 10px;
	font-size: 12px;
	position: absolute;
	white-space: nowrap;
	z-index: 2147483647;
  font-weight: 400;
}
#powerTip:before {
	content: "";
	position: absolute;
}
