.dashboard {
  &-list {
    font-size: 0;
    margin: 0 (-$dashboard-gutter);
    position: relative;
  }

  &-item {
    display: inline-block;
    float: none;
    padding: 0 $dashboard-gutter $dashboard-gutter * 2 !important;
    vertical-align: top;
    position: static;

    img {
      margin: 0;
    }

    h1 {
      margin-top: 45px;
    }
  }

  &-graph {
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      border-style: solid;
      border-width: 0 8px 14px 8px;
      border-color: transparent transparent #fff;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -8px;
      transition: transform $transition-duration-long ease;
      transform: translateY(100%);
      z-index: 5;

      .active & {
        transform: translateY(0);
      }
    }

    &:after {
      content: '';
      display: block;
      background: $color-gray-light;
      height: 14px;
      transition: transform $transition-duration-long ease;
      transform: scaleY(0);
      transform-origin: 0 0;

      .active & {
        transform: scaleY(1);
      }
    }
  }

  &-graph-detailed {
    max-height: 0;
    height: $dashboard-chart-height + $dashboard-gutter * 2 + $dashboard-chart-padding * 2 + $dashboard-chart-border;
    transition: max-height $transition-duration-long ease;

    .active & {
      max-height: $dashboard-chart-height + $dashboard-gutter * 2 + $dashboard-chart-padding * 2 + $dashboard-chart-border;
    }
  }

  &-chart {
    height: $dashboard-chart-height + $dashboard-chart-padding * 2 + $dashboard-chart-border;
    position: absolute;
    left: $dashboard-gutter;
    right: $dashboard-gutter;
    max-height: 0;
    overflow: hidden;
    transition: max-height $transition-duration-long ease;

    &-inner {
      border-bottom: $dashboard-chart-border solid $color-gray-light;
      padding: $dashboard-chart-padding 0;
      background: #fff;
    }

    .active & {
      max-height: $dashboard-chart-height + $dashboard-chart-padding * 2 + $dashboard-chart-border;
    }
  }

  &-legend {
    list-style: none;
    padding: 0;
    margin: 0 0 45px;
    font-size: $font-size-base - 2;

    li {
      display: inline-block;
      padding-left: 22px;
      margin: 0 15px 0 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 17px;
        height: 17px;
        margin: -11px 0 0;
        background: $gray-base;
      }
    }

    &-last {
      &:before {
        background: $brand-primary !important;
      }
    }
  }

  &-numbers {
    background: $color-gray-light;
    padding: 45px 0 0;

    &-list {
      font-size: 0;

      .col-xs-6 {
        float: none;
        display: inline-block;
        text-align: center;
        vertical-align: top;
      }
    }

    &-item {
      text-align: right;
      display: inline-block;
      font-size: 28px;
      line-height: 1;
      margin: 0 0 45px;

      h4 {
        font-size: $font-size-base - 2;
        text-transform: uppercase;
        margin: 0 0 5px;
      }

      &-delta {
        margin: $grid-gutter-width / 2 0 0;
        padding: $grid-gutter-width / 2 0 0;
        border-top: 1px solid #d7d7d7;

        &:before {
          content: '';
          display: inline-block;
          border-style: solid;
          border-width: 0 6px 10px;
          border-color: transparent;
          margin-right: 4px;
          vertical-align: top;
          position: relative;
          bottom: -5px;
        }

        &.up:before {
          border-color: transparent transparent $dashboard-green;
        }
        &.reverse.up:before {
          border-color: transparent transparent $brand-primary;
        }

        &.down:before {
          border-width: 10px 6px 0;
          border-color: $brand-primary transparent transparent;
        }
        &.reverse.down:before {
          border-color: $dashboard-green transparent transparent;
        }
      }
    }
  }

  &-text {
    &-last {
      color: $brand-primary;
    }
  }

  &-comments {
    font-size: $font-size-base;
    position: relative;
    margin-bottom: $dashboard-gutter * 2;

    &-inner {
      padding: $grid-gutter-width $grid-gutter-width ($grid-gutter-width - 10);
      background: $dashboard-gray;
    }

    blockquote {
      border: 0;
      padding: 0;
      font-size: 20px;
      line-height: 25px;
    }
  }

  &-spacer {
    height: 12px;
  }
}

@media (min-width: $screen-md-min) {
  .dashboard {
    &-comments {
      padding-bottom: 0 !important;

      &:before {
        content: '';
        display: block;
        padding-bottom: 56.25%;
        border-bottom: 46px solid transparent;
      }

      &.mini:before {
        padding-bottom: 65.41%;
        border-bottom: none;
      }

      &-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
      }
    }

    &-list {
      &-comments {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        &:before,
        &:after {
          display: block;
        }

        .dashboard-item {
          &:before {
            display: none;
          }
        }

        .dashboard-comments-inner {
          position: static;
        }
      }
    }
  }
}
