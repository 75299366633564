.casestudy {
	h1 {
		margin-bottom: 20px;
	}

	&-items {
		margin-top: 45px;
		font-size: 0;
	}

	&-item {
		margin-bottom: 45px;
		float: none;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		font-size: $font-size-base;


		figure {
			margin-bottom: 30px;
		}

		img {
			border-radius: 50%;
			margin-bottom: 0;
			transition: border-width $transition-duration ease;
			border: 0 solid $brand-primary;
		}

		h5 {
			font-weight: 400;
			margin: 0 0 8px;
		}

		a {
			display: block;

			&:hover,
			&:focus {
				img {
					border-width: 3px;
				}
			}
		}
	}

	&-tagline {
		display: flex;
		align-items: center;
		line-height: 1.1;
		font-weight: 400;
		margin: 45px 0 35px;
		font-size: 22px;

		img {
			border-radius: 50%;
		}

		h3 {
			font-size: 27px;
			font-weight: 700;
			margin: 0 0 18px;
			text-transform: uppercase;
		}

		p {
			margin: 0;
		}
	}

	&-more {
		margin-top: 45px;
		text-transform: uppercase;

		h5 {
			color: $brand-primary;
			font-weight: 700;
			margin: 0 0 10px;
		}

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}

		li {
			margin-bottom: 10px;

			&:before {
				display: none;
			}
		}
	}
}
