// ============================================================================
// Scaffolding
// ============================================================================

body {

}

.strong {
  font-weight: bold;
}

.overlay {
  position: relative;
  &:before {
    content: '';
    transition: opacity ease 500ms;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0;
  }
  &:hover, &:active, &:focus {
    &:before {
      opacity: .5;
    }
  }
}

.img-centered {
  &.img-responsive {
  margin-left: auto;
  margin-right: auto;
  }
  .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }
}

.img-restricted {
  &.img-responsive {
    width: 100%;
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
  .img-responsive {
    width: 100%;
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
}

.map {
  min-height: 200px;
  @media (min-width: $screen-sm-min) {
    min-height: 400px;
  }
}