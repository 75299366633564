img {
  &.left {
    float: left !important;
    margin: 0 10px 10px 0 !important;
  }

  &.right {
    float: right !important;
    margin: 0 0 10px 10px !important;
  }

  &.center {
    margin: 0 auto 10px;
    text-align: center;
    display: block;
  }

  &.leftAlone {
    display: block;
  }
}
