.specialists {
  &-wrap {
    background-color: $color-gray-dark;
    color: $color-text-white;

    padding-top: 37px;
    padding-bottom: 30px;
  }

  .contact-team {
    padding: 30px 0 20px;
  }
}
