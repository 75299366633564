.contact {
	background-color: $color-gray-dark;
	color: $color-text-white;

	&-team {
		font-size: 0;
		margin: 0 auto;
		line-height: 1.2;

		&-item {
			margin: 0 auto 20px;
      font-size: 12px;
      display: block;
      text-align: left;
      width: 290px;

			&-image {
				padding-right: 0;
				width: 100px;

				img {
					border-radius: 50%;
					margin: 13px 0 0;
				}
			}

			&-desc {
				width: 220px;
			}

			h5 {
        font-weight: 400;
        margin: 0 0 5px;
        font-size: 12px;
			}

			&-location {
        margin: 8px 0 5px;
        font-weight: 400;
			}

			p {
				margin-bottom: 5px;
			}

			&-link {
				color: #fff;

				&:after {
					display: none;
				}

				.active & {
					color: #fff;
				}
			}
		}
	}

	&-form {
		max-width: 420px;
		margin: 45px auto;

		.form-group {
			margin: 0 -5px 10px;
		}

		div[class^="col-sm-"],
		div[class*=" col-sm-"] {
			padding-left: 5px;
			padding-right: 5px;
		}

		.col-sm-6:first-child {
			margin-bottom: 10px;
		}

		.form-control {
			font-size: 12px;
			color: $text-color;
			padding: 11px 12px;
		}

		select.form-control {
			/* autoprefixer: off */
			-webkit-appearance: none;
			appearance: none;
			-moz-appearance: none;
		    text-indent: 0.01px;
		    text-overflow: '';
			cursor: pointer;
			position: relative;
			font-size: 12px;
			background: #fff url(../images/select.png) no-repeat right 15px center;
			padding-right: 47px;
			color: #777;

			&.not-empty {
				color: $text-color;
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.contact {
		&-form {
			margin-top: 85px;

			.col-sm-6:first-child {
				margin-bottom: 0;
			}
		}
	}
}

@media (min-width: 640px) {
  .contact {
    &-team {

      &-item {
        width: 50%;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}
