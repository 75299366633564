/*
 * $start    : Start point for loop (usually zero)
 * $limit    : Limit of loop
 * $interval : Loop interval / class increment
 * $real     : Real number to use for loop i variable (calculated not set)
*/

$start: 0;
$limit: 100;
$interval: 5;

$real: $limit / $interval;

// Margins


// All
@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-m-a-#{($i * $interval) + $start} {
      margin: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-m-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-m-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-m-a-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-m-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-m-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-m-a-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-m-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-m-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-m-a-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-m-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-m-a-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        margin: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Top

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-m-t-#{($i * $interval) + $start} {
      margin-top: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-m-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-m-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-m-t-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-m-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-m-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-m-t-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-m-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-m-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-m-t-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-m-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-m-t-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        margin-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Right

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-m-r-#{($i * $interval) + $start} {
      margin-right: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-m-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-m-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-m-r-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-m-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-m-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-m-r-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-m-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-m-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-m-r-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-m-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-m-r-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Bottom

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-m-b-#{($i * $interval) + $start} {
      margin-bottom: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-m-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-m-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-m-b-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-m-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-m-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-m-b-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-m-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-m-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-m-b-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-m-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-m-b-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Left

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-m-l-#{($i * $interval) + $start} {
      margin-left: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-m-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-m-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-m-l-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-m-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-m-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-m-l-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-m-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-m-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-m-l-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-m-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-m-l-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        margin-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

// X Axis

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-m-x-#{($i * $interval) + $start} {
      margin-left: #{($i * $interval) + $start}px;
      margin-right: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-m-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-m-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-m-x-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-m-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-m-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-m-x-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-m-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-m-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-m-x-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-m-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-m-x-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        margin-left: #{($i * $interval) + $start}px;
        margin-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Y Axis

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-m-y-#{($i * $interval) + $start} {
      margin-top: #{($i * $interval) + $start}px;
      margin-bottom: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-m-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-m-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-m-y-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-m-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-m-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-m-y-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-m-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-m-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-m-y-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-m-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-m-y-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        margin-top: #{($i * $interval) + $start}px;
        margin-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Padding

// All

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-p-a-#{($i * $interval) + $start} {
      padding: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-p-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-p-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-p-a-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-p-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-p-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-p-a-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-p-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-p-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-p-a-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-p-a-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-p-a-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        padding: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Top

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-p-t-#{($i * $interval) + $start} {
      padding-top: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-p-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-p-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-p-t-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-p-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-p-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-p-t-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-p-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-p-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-p-t-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-p-t-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-p-t-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        padding-top: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Right

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-p-r-#{($i * $interval) + $start} {
      padding-right: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-p-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-p-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-p-r-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-p-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-p-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-p-r-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-p-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-p-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-p-r-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-p-r-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-p-r-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Bottom

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-p-b-#{($i * $interval) + $start} {
      padding-bottom: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-p-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-p-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-p-b-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-p-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-p-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-p-b-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-p-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-p-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-p-b-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-p-b-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-p-b-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Left

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-p-l-#{($i * $interval) + $start} {
      padding-left: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-p-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-p-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-p-l-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-p-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-p-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-p-l-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-p-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-p-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-p-l-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-p-l-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-p-l-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        padding-left: #{($i * $interval) + $start}px;
      }
    }
  }
}

// X Axis

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-p-x-#{($i * $interval) + $start} {
      padding-left: #{($i * $interval) + $start}px;
      padding-right: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-p-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-p-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-p-x-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-p-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-p-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-p-x-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-p-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-p-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-p-x-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-p-x-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-p-x-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        padding-left: #{($i * $interval) + $start}px;
        padding-right: #{($i * $interval) + $start}px;
      }
    }
  }
}

// Y Axis

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-p-y-#{($i * $interval) + $start} {
      padding-top: #{($i * $interval) + $start}px;
      padding-bottom: #{($i * $interval) + $start}px;
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-p-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-u-p-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-xs-min) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-xs-d-p-y-#{($i * $interval) + $start} {
      @media (max-width: $screen-xs-max) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-p-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-u-p-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-sm-min) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-sm-d-p-y-#{($i * $interval) + $start} {
      @media (max-width: $screen-sm-max) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-p-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-u-p-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-md-min) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-md-d-p-y-#{($i * $interval) + $start} {
      @media (max-width: $screen-md-max) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-u-p-y-#{($i * $interval) + $start} {
      @media (min-width: $screen-lg-min) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}

@for $i from 0 through $real {
  @if(($i * $interval) + $start <= $limit) {
    .bs-lg-d-p-y-#{($i * $interval) + $start} {
      @media (max-width: $screen-lg) {
        padding-top: #{($i * $interval) + $start}px;
        padding-bottom: #{($i * $interval) + $start}px;
      }
    }
  }
}
