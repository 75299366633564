.gallery {
	.img-responsive {
		margin-bottom: 0;
	}

	&-item {
		margin-bottom: $grid-gutter-width;

		a {
			display: block;
		}

		h5 {
			a {
				display: inline;
			}
		}
	}
}

.pagination {
	li {
		&:before {
			display: none;
		}
	}

	a {
		border: none !important;
		margin: 0 !important;

		&:after {
			display: none;
		}
	}
}