.stages {
  &-holder &-svg {
    overflow: hidden;
    position: relative;
    padding-bottom: 104.485488127%;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-holder {
    background-color: $color-gray-dark;
    color: $color-text-white;
  }

  &-list {
    padding: 0;
    position: relative;

    &-arrow {
      position: absolute;
      left: -6px;
      top: 233px;
      display: none;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 15px solid $brand-primary;
      z-index: 5;
    }

    &:before,
    &:after {
      content: '';
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 150px;
      z-index: 1;
      background: -moz-linear-gradient(top, rgba($color-gray-dark,1) 0%, rgba($color-gray-dark,1) 25%, rgba($color-gray-dark,0) 100%);
      background: -webkit-linear-gradient(top, rgba($color-gray-dark,1) 0%,rgba($color-gray-dark,1) 25%,rgba($color-gray-dark,0) 100%);
      background: linear-gradient(to bottom, rgba($color-gray-dark,1) 0%,rgba($color-gray-dark,1) 25%,rgba($color-gray-dark,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#252525', endColorstr='#00252525',GradientType=0 );
    }

    &:after {
      top: auto;
      bottom: 0;
      background: -moz-linear-gradient(top, rgba($color-gray-dark,0) 0%, rgba($color-gray-dark,1) 75%, rgba($color-gray-dark,1) 100%);
      background: -webkit-linear-gradient(top, rgba($color-gray-dark,0) 0%,rgba($color-gray-dark,1) 75%,rgba($color-gray-dark,1) 100%);
      background: linear-gradient(to bottom, rgba($color-gray-dark,0) 0%,rgba($color-gray-dark,1) 75%,rgba($color-gray-dark,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00252525', endColorstr='#252525',GradientType=0 );
    }

    &-wrap {
      padding: 45px $grid-gutter-width / 2;
    }

    &-viewport {
      position: relative;
      overflow: hidden;
      z-index: 0;
    }

    &-item {
      margin-top: 40px;
      position: relative;

      h4 {
        font-weight: 700;
        margin: 0 0 5px;
      }

      h6 {
        font-size: 12px;
        margin: 0 0 10px;
        text-transform: uppercase;
      }

      p {
        margin: 0 0 10px;
      }

      a {
        font-weight: 400;

        &:before {
          content: '▶';
          position: absolute;
          right: -15px;
          top: 5px;
          font-size: 9px;
        }
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .stages {
    &-svg {
      margin-top: 30px;
    }

    &-list {
      &-arrow {
        display: block;
      }

      &:before,
      &:after {
        display: block;
      }

      &-wrap {
        padding: 120px $grid-gutter-width / 2 200px 25px;
        border-left: 1px solid #909090;
      }

      &-viewport {
        max-height: 487px;
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .stages {
    &-svg {
      margin-top: 47px;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .stages {
    &-svg {
      margin-top: 5px;
    }
  }
}
