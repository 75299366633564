.phases {
	h1 {
		margin-bottom: 0;
	}

	&-svg {
		margin-top: 45px;
		overflow: hidden;
		position: relative;
		padding-bottom: 102.06185567%;

		svg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	&-list {
		padding: 0;
		position: relative;
		font-size: 12px;

    &-arrow {
      position: absolute;
      left: -6px;
      top: 233px;
      display: none;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 15px solid $brand-primary;
      z-index: 5;
    }

    &:before,
		&:after {
			content: '';
			display: none;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 80px;
			z-index: 1;
			background: -moz-linear-gradient(top, rgba(#fff,1) 0%, rgba(#fff,.8) 25%, rgba(#fff,0) 100%);
			background: -webkit-linear-gradient(top, rgba(#fff,1) 0%,rgba(#fff,.8) 25%,rgba(#fff,0) 100%);
			background: linear-gradient(to bottom, rgba(#fff,1) 0%,rgba(#fff,.8) 25%,rgba(#fff,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
		}

		&:after {
			top: auto;
			bottom: 0;
			background: -moz-linear-gradient(top, rgba(#fff,0) 0%, rgba(#fff,.8) 75%, rgba(#fff,1) 100%);
			background: -webkit-linear-gradient(top, rgba(#fff,0) 0%,rgba(#fff,.8) 75%,rgba(#fff,1) 100%);
			background: linear-gradient(to bottom, rgba(#fff,0) 0%,rgba(#fff,.8) 75%,rgba(#fff,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
		}

		&-wrap {
			padding: 45px $grid-gutter-width / 2;
		}

		&-viewport {
			position: relative;
			overflow: hidden;
			z-index: 0;
		}

		&-item {
			margin-top: 40px;
			position: relative;

			h4 {
				font-weight: 700;
				font-size: 14px;
				color: $brand-primary;
				margin: 0 0 5px;
			}

			h6 {
				font-size: 12px;
				font-weight: 700;
				margin: 0 0 10px;
			}

			p {
				margin: 0 0 10px;
			}

			ul {
				margin: 0 0 10px;
				padding: 0;
			}

			li {
				margin-top: 5px;

				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.phases {
		&-svg {
			margin-top: 100px;
		}

		&-list {
      &-arrow {
        display: block;
      }

			&:before,
			&:after {
				display: block;
			}

			&-wrap {
				padding: 120px $grid-gutter-width / 2 200px 30px;
        border-left: 1px solid #b3b2b2;
			}

			&-viewport {
				max-height: 700px;
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.phases {
		&-svg {
			margin-top: 130px;
		}

		&-list {
			&-viewport {
				max-height: 670px;
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.phases {
		&-svg {
			margin-top: 80px;
		}
	}
}
