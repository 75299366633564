// ============================================================================
// Typography Stylesheet
//
// Defines additional styles which can be applied in the CMS
// ============================================================================

// ATTENTION: Button styles are now in 'editor.scss' due to the unfortunate repercussions of using @extend

// Bootstrap Styles
// Styles which you want users to be able to select in the CMS
// ----------------------------------------------------------------------------

.lead {
  // For the style to register, it needs some kind of definition.
  // Just apply the default display value
  display: block;
}

* {
  word-wrap: break-word;
}

p {
  margin-bottom: 10px;
}

h1 {
  text-transform: uppercase;
  margin: 0 0 45px;
  font-weight: 400;
  // font-size: ;
  // line-height: ;
  sup {
    font-size: 55%;
    top: -.7em;
  }

  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h2 {
  margin: 0 0 25px;
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h3 {
  color: $brand-primary;
  text-transform: uppercase;
  margin: 0 0 15px;
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h4 {
  margin: 0 0 15px;
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h5 {
  margin: 0 0 15px;
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h6 {
  margin: 0 0 15px;
  // font-size: ;
  // line-height: ;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

ol,
ul {
  margin-bottom: 10px;
}

ul:not(.nav) {
  li:not(.link, .current) {

  }
}

table p:last-child {
  margin-bottom: 0;
}

a {
  transition: all $transition-duration ease;;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

ul + h3 {
  margin-top: 15px;
}
p + h3 {
  margin-top: 15px;
}

hr {
  margin: 30px 0;
}

blockquote {

}

// Custom Styles
//
// Styles which will appear in the Styles dropdown in the CMS
// ----------------------------------------------------------------------------

.no-break {
  white-space: nowrap;
}
