.heifer {
	&-calc {
		margin-top: 45px;
		text-align: center;
		position: relative;
		overflow: hidden;
		max-height: 290px;
		transition: max-height $transition-duration-long * 3 ease;
		padding-bottom: 290px;

		&.active {
			max-height: 1800px;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			top: 50px;
			bottom: 50px;
			border-left: 1px dashed $color-gray-dark;
		}
	}

	&-calc.active &-key {
		opacity: 1;
	}

	&-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}

	&-inner {
		padding: 40px;
	}

	&-target {
		width: 290px;
		height: 290px;
		background: $color-gray-dark;
		color: #fff;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -145px;

		input {
			background: none;
			border: 0;
			border-bottom: 1px solid #fff;
			outline: none !important;
			text-align: center;
			font-size: 45px;
			width: 100px;
			margin: 10px 0;
			height: 62px;
			line-height: 60px;
			padding: 1px 5px;
			color: #fff;
			display: inline-block;


			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				/* autoprefixer: off */
				-webkit-appearance: none;
			}
			& {
				/* autoprefixer: off */
				-moz-appearance: textfield;
			}

			&:focus {
				box-shadow: 0 6px 8px -6px rgba($brand-primary,.6);
			}
		}

		.btn {
			padding: 8px 30px;
		}
	}

	&-key {
		width: 240px;
		height: 240px;
		background: $color-gray-light;
		margin: $grid-gutter-width auto;
		position: relative;
		opacity: 0;
		transition: opacity $transition-duration-long ease;

		&:first-child {
			opacity: 1;
		}

		big {
			font-size: 45px;
			margin-top: 10px;
		}
	}
}

@media print {
  .heifer {
    &-calc {
      padding-top: 40px;
      padding-bottom: 250px;

      &.active {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &-key {
      background: $color-gray-light !important;
      -webkit-print-color-adjust: exact;
      height: auto;
      padding: 15px 0 8px;
      border-radius: 0;
      page-break-inside: avoid;

      p {
        margin: 0;
      }
    }

    &-wrap {
    }

    &-target {
      background: $color-gray-dark !important;
      -webkit-print-color-adjust: exact;
      page-break-inside: avoid;

      .active & {
        position: relative;
        margin: 0 auto;
        left: 0;
      }

      * {
        color: #fff !important;
      }

      .btn {
        display: none;
      }
    }
  }
}
