.science {
	h1 {
		margin-bottom: 20px;
	}

	&-intro {
		font-size: 18px;
	}

	&-image {
		margin: 45px 0;
		position: relative;

		img {
			margin: 0;
		}
	}

	&-hints {
		position: absolute;
		left: $grid-gutter-width / 2;
		right: $grid-gutter-width / 2;
		top: 0;
		bottom: 0;
		text-align: center;
		color: #fff;
		font-size: 13px;
		display: none;

		&-item {
			position: absolute;
			width: 34px;
			height: 34px;
			border-radius: 50%;
			border: 2px solid #fff;
			transition: all $transition-duration ease;
			background: transparent;
			transform: translate3d(-50%, -50%, 0);
			cursor: pointer;
			outline: none;
			overflow: hidden;
      font-weight: 400;

			&-code,
			&-title {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				transition: opacity $transition-duration ease;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&-title {
				opacity: 0;
			}

			&:hover,
			&:focus {
				width: 86px;
				height: 86px;
				background-color: $brand-primary;
				border-width: 2px;
				z-index: 10;
			}

			&:hover &-code,
			&:focus &-code {
				opacity: 0;
			}
			&:hover &-title,
			&:focus &-title {
				opacity: 1;
			}
		}
	}
}

@media (min-width: $screen-xs-min) {
	.science {
		&-hints {
			display: block;
		}
	}
}
