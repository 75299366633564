.video {
	background-color: $color-gray-dark;
	color: $color-text-white;

	main {
		padding-bottom: 0;
	}

	h1 {
		margin-bottom: 40px;
	}

	&-content {
		position: relative;
		z-index: 1;

		&-image {
			img {
				border-radius: 50%;
			}
		}
	}

	&-subtitle {
		font-size: 27px;
		text-transform: uppercase;
		color: $brand-primary;
		margin: 23px 0 15px;
	}

	&-item {
		padding: 45px 0;
		text-align: right;

		&:nth-child(even) {
			background: $color-gray-light;
			color: $text-color;
			text-align: left;
		}

		h3 {
			font-size: 27px;
			margin-bottom: 15px;
		}

		&-tagline {
			font-size: 18px;
			text-transform: uppercase;
			margin-bottom: 15px;
			line-height: 1.1;
			font-weight: 400;
		}

		&-thumbnail {
			display: inline-block;
			position: relative;
			outline: none !important;
			max-width: 100%;

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				width: 50px;
				height: 50px;
				margin: -25px 0 0 -25px;
				background: $brand-primary;
				border-radius: 50%;
        opacity: .8;
				transition: opacity $transition-duration ease;
			}

			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				width: 0 !important;
				height: 0;
				border-top: 13px solid transparent;
				border-bottom: 13px solid transparent;
				border-left: 17px solid #fff;
				margin: -13px 0 0 -6px;
			}

			&:hover,
			&:focus {
				&:before {
					opacity: .9;
				}
			}

			img {
				margin-bottom: 0;
			}
		}

    &-title {
      background: $gray-base;
      color: $color-text-white;
      font-size: 18px;
      line-height: 24px;
      padding: 11px;
      display: block;
      text-align: center;

      &.text-left {
        text-align: left;
      }
    }
	}
}

@media (min-width: $screen-sm-min) {
	.video {
		&-content {
			display: flex;
			align-items: flex-start;

			&-image {
				align-self: flex-end;
			}
		}
	}
}
