// ============================================================================
// Header
// ============================================================================

html {
	position: relative;
	min-height: 100%;
}

body {
	background: no-repeat top;
	background-size: cover;
	background-attachment: fixed;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-top: 74px;
	padding-right: 0 !important;
}

.container-full {
	width: auto;
}

.text-body {
  font-size: $font-size-base;
}

.clear {
  clear: both;
}

.caret {
  display: none;
  width: 0;
  height: 0;
  margin-left: 2px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  border-top: 6px dashed;
  border-top: 6px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

main {
	padding: 45px 0 35px;

	a {
		position: relative;
		display: inline-block;

		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			border-bottom: 1px solid $link-hover-color;
			left: 0;
			bottom: 0;
			transition: width $transition-duration ease;
		}

		&:hover,
		&:focus {
			&:after {
				width: 100%;
			}
		}

		&.noline {
			&:after {
				display: none;
			}
		}
	}

	.btn:after {
		display: none;
	}

	.img-responsive {
		margin: 0 auto 15px;
	}

  img.leftAlone {
    max-width: 100%;
    height: auto;
    display: block;
  }

	ul {
		list-style: none;

		li {
      position: relative;

			&:before {
				content: '▲';
				color: $brand-primary;
				font-size: 7px;
				vertical-align: middle;
				display: inline-block;
				margin: 0 0 0 -12px;
        top: .5em;
        position: absolute;
			}
		}
	}
}

.fa {
	transition: color $transition-duration ease;

  main &,
  .specialists-wrap &,
  .calccomp-performance & {
    color: $brand-primary;
    vertical-align: middle;
    margin-right: 6px;
    font-size: 12px;

    &-envelope {
      font-size: 10px;
      margin-right: 5px;
      position: relative;
      top: -1px;
    }
  }

  &-facebook-official {
    position: relative;

    &:after {
      content: '';
      top: 2px;
      bottom: 2px;
      left: 0;
      right: 1px;
      border-radius: 3px;
      background: $color-text-white;
      opacity: 0;
      transition: all $transition-duration ease;
      display: block;
      position: absolute;
      z-index: -1;
    }
  }

  .btn & {
    color: $color-text-white;
  }
}

.btn {
	transition: all $transition-duration ease;

	&-sm {
		padding: 3px 15px;
	}

	&-lg {
		width: 100%;
		font-size: 14px;
		padding: 13px 16px;
	}
}

.modal {
	color: $text-color;
	padding-right: 0 !important;

	&-embed &-body {
		padding: 0;
	}
	&-embed &-header {
		border-bottom: 0;
	}
}

.hidden {
	display: none;
}

.nocase {
	text-transform: none;
}
.case {
	text-transform: uppercase;
}

.nomargin {
	margin: 0;
}

.well {
	box-shadow: none;
}

table {
	margin-bottom: 9px;

	th {
		font-weight: 400;
	}
}

.magic {
  position: fixed;
  top: -400px;
  left: -400px;
}

.highlight {
	color: $brand-primary;
}

svg {
	transition: transform $transition-duration-long ease;
	user-select: none;

	.sector {
		cursor: pointer;
		fill-opacity: 0;
	}
	.rb {
		fill: #141413;
		transition: fill $transition-duration ease;
	}
	.r {
		fill: #9d9d9c;
		transition: fill $transition-duration ease;
	}
	.s {
		fill: #b3b2b2;
		transition: fill $transition-duration ease;

		&-w {
			&:hover {
				.s {
					fill: darken(#b3b2b2, 5%);
				}
			}
		}
	}
	.b {
		stroke: #fff;
		stroke-width: 1;

		&-t {
		    stroke: #414042;
		    stroke-width: .5;
		}
	}
	.t {
		font-family: $font-family-base;
		text-transform: uppercase;
		font-size: 7px;
		font-weight: 700;
		fill: #fff;
		letter-spacing: .2px;
		cursor: default;
		text-anchor: middle;
		pointer-events: none;
		transition: fill $transition-duration ease;

		&-s {
			text-anchor: start;
		}

		&-n {
			letter-spacing: -.3px;
		}
	}

	.p {
		fill: #6e181e;
		stroke: #414042;
		stroke-width: 0.5;
		transition: all $transition-duration ease;

		&-e {
			stroke: #414042;
			stroke-width: 0.5;
			transition: all $transition-duration ease;
		}

		&-w {
			&:hover {
				.p {
					fill: lighten(#6e181e, 10%);
				}
			}
		}
	}

	a & {
		pointer-events: none;
	}

  a {
    outline: none !important;
  }

	.product {
        cursor: pointer;
        fill-opacity: 0;
    }

    .region {
   		fill: #b3b2b2;
    	transition: fill $transition-duration ease;
    	outline: none !important;

      &:not(.disabled) {
        cursor: pointer;

      	&:hover {
      		fill: darken(#b3b2b2, 10%);
      	}
      	&.active {
      		fill: #e30613;

      		&:hover {
      			fill: lighten(#e30613, 10%);
      		}
      	}
      }
   	}

	&.big {
		.t {
			font-size: 16.8824px;

			&-p {
				font-size: 7.5px;
			}
		}
	}

	.dark & {
		.b {
			stroke: $color-gray-dark;
		}
		.s {
			fill: #6d6e71;

			&-w {
		   		&:hover {
		   			.s {
		   				fill: lighten(#6d6e71, 5%);
		   			}
		   		}
		   	}
		}
		.rb {
			fill: #6f0611;
		}
		.r {
			fill: #ed1c24;
		}
	}
}
@for $i from 1 through 6 {
	.sector-#{$i} {
		.s-#{$i} {
			fill: #e30613 !important;

			.product-formula-1 & {
				fill: $color-yellow !important;
			}
			.product-formula-20 & {
				fill: $color-green !important;
			}
		}
		&.dark {
			.s-#{$i} {
				fill: #cb1820 !important;
			}
		}
		.rb-#{$i} {
			fill: #760e02;

			.product-formula-1 & {
				fill: darken($color-yellow, 20%) !important;
			}
			.product-formula-20 & {
				fill: darken($color-green, 20%) !important;
			}
		}
		.r-#{$i} {
			fill: #e30613;

			.product-formula-1 & {
				fill: $color-yellow !important;
			}
			.product-formula-20 & {
				fill: $color-green !important;
			}
		}
	}
}

#sb-title-inner,
#sb-info-inner,
#sb-loading-inner,
div.sb-message {
	font-family: $font-family-base !important;
	font-weight: 400 !important;
}
#sb-title-inner {
	font-size: 14px !important;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

@media (min-width: $screen-sm-min) {
	body {
		margin-bottom: 40px;
	}

	main {
		.img-responsive {
			margin: 0 0 15px;
		}
	}
}

@media (min-width: $screen-md-min) {
  body {
    padding-top: 0;
  }
  .caret {
    float: none;
  }

  .flex {
    &-row {
      display: flex;
      align-items: center;
    }

    &-col {
      float: none;
    }
  }
}

@media print {
  body {
    padding-top: 0;
  }

  a {
    &[href]:after {
      content: '';
    }
  }
  .well {
    background: $well-bg !important;
    -webkit-print-color-adjust: exact;
  }

  .hidden-print {
    display: none;
  }
}
