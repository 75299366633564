.formulations {
	h1 {
		margin-bottom: 15px;
	}

	&-intro {
		font-size: 18px;
		padding-top: 45px;
		padding-bottom: 0;
	}

	&-content {
		background: $color-gray-dark;
		color: $color-text-white;
		font-size: 12px;

		&-image {
			border-radius: 50%;
		}
	}

	&-points {
		padding: 85px 0 30px;
		background: no-repeat bottom;
		background-size: cover;
	}

	&-point {
		position: relative;
		margin-bottom: 55px;

		&:before {
			content: '';
			display: block;
			padding-bottom: 100%;
		}

		&-body {
			position: absolute;
			left: $grid-gutter-width / 2;
			right: $grid-gutter-width / 2;
			top: 0;
			bottom: 0;
			background: rgba(#fff,.8);
			border-radius: 50%;
			white-space: nowrap;
			font-size: 0;
			box-shadow: 0 6px 6px rgba(0,0,0,.2);
			color: $text-color;

			&:before {
				content: '';
				height: 100%;
				width: 1.5%;
				display: inline-block;
				vertical-align: middle;
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				top: -7px;
				width: 41px;
				height: 43px;
				margin-left: -20px;
				background: url(../images/arrow.png) no-repeat center;
				background-size: 100% 100%;
			}

			&:hover,
			&:focus {
				color: $text-color;
			}

			span {
				display: inline-block;
				vertical-align: middle;
				width: 97%;
				text-align: center;
				white-space: normal;
				font-size: 25px;
				line-height: 1;
			}

			big {
				font-size: 40px;
				display: block;
			}

			small {
				font-size: 13px;
				display: block;
				max-width: 140px;
				margin: 0 auto;
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	.formulations {
		&-intro {
			padding-bottom: 45px;
		}

		&-content {
			&-image {
				margin-top: 45px !important;
			}
		}
	}
}

@media (min-width: $screen-xs-min) {
	.formulations {
		&-point {
			&-body {
				span {
					font-size: 35px;
				}

				big {
					font-size: 50px;
				}

				small {
					font-size: 15px;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.formulations {
		&-intro {
			.row {
				display: flex;
				align-items: flex-start;
			}

			&-img {
				align-self: flex-end;
			}
		}
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (   min--moz-device-pixel-ratio: 1.5),
        only screen and (     -o-min-device-pixel-ratio: 3/2),
        only screen and (        min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 192dpi) {
    .formulations-point-body:after {
        background-image: url(../images/arrow@2x.png);
    }
}
@media  only screen and (-webkit-min-device-pixel-ratio: 2.5),
        only screen and (   min--moz-device-pixel-ratio: 2.5),
        only screen and (     -o-min-device-pixel-ratio: 5/2),
        only screen and (        min-device-pixel-ratio: 2.5),
        only screen and (min-resolution: 288dpi) {
    .formulations-point-body:after {
        background-image: url(../images/arrow@3x.png);
    }
}