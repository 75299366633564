.map-interaction-disabled {
}

.map-interaction-enabled {
  margin: 0 !important;
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 9999 !important;
}

.map-ui-expand:hover {
  color: #000 !important;
}