// ============================================================================
// Forms
// ============================================================================

.form {
	&-control {
		&:focus {
			box-shadow: 0 0 8px rgba($brand-primary,.6);
		}
	}
}

.checkbox {
  margin-bottom: 15px;
}

textarea {
  resize: none;
}
