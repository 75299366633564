.events {
	background-color: $color-gray-dark;
	color: #fff;

	main {
		font-size: 12px;
	}

    h1 {
    	margin-bottom: 45px;
    }

	&-item {
		.col-md-10 {
			position: relative;
			padding-top: 30px;
			padding-bottom: 30px;

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: $grid-gutter-width / 2;
				right: $grid-gutter-width / 2;
				top: 0;
				border-top: 1px solid $brand-primary;
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: $grid-gutter-width / 2;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 8px solid #e30613;
			}
		}

		.col-sm-2 {
			margin-top: 15px;

			.btn {
				width: 100%;
			}
		}

		&-date {
			font-size: 28px;
			line-height: 1;

			&-start,
			&-end {
				&:before {
					content: 'From:';
					display: block;
					font-size: 12px;
					color: $brand-primary;
					font-weight: 400;
				}
			}

			&-end {
				margin-top: 4px;
				&:before {
					content: 'To:';
				}
			}
		}

		&-info {
			padding-top: 3px;

			a {
				font-weight: 400;
			}
		}

		&-title {
			font-size: 15px;
			margin: 4px 0 15px;
		}

		&-join {
			margin-top: 25px;

			h6 {
				font-weight: 700;
				margin: 0 0 10px;
			}

			&-details {
				display: table;
			}

			&-row {
				display: table-row;

				strong,
				span {
					display: table-cell;
					vertical-align: top;
					padding-top: 2px;
				}

				span {
					padding-left: 5px;
				}
			}
		}

		&-other {
			margin-top: 25px;

			h6 {
				font-weight: 700;
				margin: 0 0 10px;
			}

			p {
				margin: 0;
				padding-top: 2px;
			}
		}

	}

	&-message {
		border-left: 4px solid #fff;
		padding: 5px 0 5px 20px;
		margin-bottom: 50px;
		font-weight: 400;

		&.error {
			border-color: $brand-primary;
		}
	}
}

@media (min-width: $screen-sm-min) {
	.events {
		&-item {
			.row {
				display: flex;
				align-items: flex-start;
			}

			.col-sm-2 {
				align-self: center;
				margin-top: 0;
			}
		}
	}
}
