.news {
	h1 {
		margin-bottom: 45px;
	}

  &-date {
    margin: -45px 0 45px;
    color: #000;
  }

	&-list {
		margin-top: 45px;

		&-item {
			margin-bottom: 45px;

      &-date {
        margin: -15px 0 15px 10px;
        font-size: .92em;
        color: #000;
      }
		}

		h3 {
			padding-bottom: 10px;
			border-bottom: 1px solid $brand-primary;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 100%;
				left: 0;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 8px solid #e30613;
			}
		}
	}

  &-pasture {
    margin-bottom: $grid-gutter-width / 2;
    font-size: 0;

    img {
      margin-bottom: 10px;
      height: 90px;
      width: auto;
      margin-right: 6px;
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .news {
    &-pasture {
      img {
        height: 117px;
        margin-right: 8px;
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .news {
    &-pasture {
      img {
        height: 88px;
        margin-right: 6px;
      }
    }
  }
}

@media (min-width: $screen-lg-min) {
  .news {
    &-pasture {
      img {
        height: 110px;
        margin-right: 7px;
      }
    }
  }
}
