.product {
	h1 {
		margin-bottom: 30px;
	}

	&-type {
		font-size: 0.4em;
		font-weight: 700;

		span {
			font-weight: 400;
		}
	}

	&-svg {
		position: relative;
		padding-bottom: 104.485488127%;

    &-wrap {
      max-width: 300px;
      margin: 0 auto 35px;
    }

		.sector {
			cursor: default;
		}

		svg {
			pointer-events: none;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	&-consider {
		h3 {
			text-transform: none;
		}

		ul {
			margin: 0 -15px 5px;
			padding: 0;
		}

		li {
			padding: 0 15px 30px;
			display: inline-block;

			&:before {
				display: none;
			}
		}
	}

	&-tagline {
		br {
			display: none;
		}
	}

	&-title {
		font-size: 22px;
		text-transform: uppercase;
		line-height: 1;
		color: $text-color;

		&:after {
			display: none;
		}

		&:focus,
		&:hover {
			color: $brand-primary;

			&.product-formula-1 {
				color: $color-yellow;
			}
			&.product-formula-20 {
				color: $color-green;
			}
		}

		&-type {
			font-size: 0.5em;
			font-weight: 700;
			color: $text-color;
		}
	}

	&-content {
		font-size: 12px;

		h5 {
			color: $brand-primary;
			text-transform: uppercase;
			margin: 5px 0;
		}

		ul {
			margin: 5px 0;
			display: inline-block;
		}

		p {
			margin: 5px 0;
			display: inline-block;
		}
	}

	&-nutrient {
		background: $color-gray-dark;
		color: $color-text-white;
		padding: 45px 0 25px;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			z-index: 0;
			width: 50%;
			left: 50%;
			top: 79px;
			border-bottom: 1px solid $brand-primary;
		}

		.container {
			position: relative;
			z-index: 1;
		}

		h3 {
			margin-bottom: 25px;
			border-bottom: 1px solid $brand-primary;
			height: 35px;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 100%;
				left: 0;
				width: 0;
				height: 0;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 8px solid $brand-primary;
			}
		}

		ul {
			list-style: none;
			margin: 0 -10px;
			padding: 0;
			font-size: 0;
		}

		&-item {
			display: inline-block;
			padding: 0 10px 20px;
			line-height: 1;
			text-transform: uppercase;
			width: 50%;
			font-size: $font-size-base;

			h5 {
				font-size: 13px;
				line-height: 1;
				margin: 0;
			}

			&-help {
				font-size: 8px;
				margin-bottom: 5px;
			}

			&-val {
				font-size: 24px;
			}

			&-unit {
				font-size: 8px;
				font-weight: 700;
				top: .85em;
				vertical-align: top;
			}
		}
	}

	&-disclaimer {
		margin-bottom: 30px;
		font-size: 12px;
		font-weight: 400;
	}

	&-next,
	&-prev {
		text-align: right;
		text-transform: uppercase;
		font-weight: 400;
		margin-top: 15px;

    &.nomargin {
      margin: 0;
    }
    &.top {
      margin: 0 0 15px;
    }

		a {
			margin-right: 15px;

			&:before {
				content: '';
				border-style: solid;
				border-width: 4px 0 4px 8px;
				border-color: transparent transparent transparent $brand-primary;
				display: inline-block;
				position: absolute;
				right: -15px;
				top: 50%;
				margin-top: -4px;
			}
		}
	}
	&-prev {
		text-align: left;

		a {
			margin: 0 0 0 15px;

			&:before {
				border-width: 4px 8px 4px 0;
				border-color: transparent $brand-primary transparent transparent;
				right: auto;
				left: -15px;
			}
		}
	}
}

@media (min-width: $screen-xs-min) {
	.product {
		&-nutrient {
			&-item {
				width: 33.33333%;

				h5 {
					font-size: 14px;
				}

				&-help {
					font-size: 9px;
				}

				&-val {
					font-size: 28px;
				}

				&-unit {
					font-size: 10px;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.product {
		&-svg {
			margin: 0 0 35px;
		}

		&-content {
			column-count: 2;
			column-gap: $grid-gutter-width;
		}

		&-nutrient {
			padding: 75px 0 55px;

			&:before {
				top: 109px;
			}

			&-item {
				width: 25%;
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.product {
		&-nutrient {
			&-item {
				width: 20%;
			}
		}
	}
}

@media print {
  .product {
    &-nav,
    &-prev,
    &-next {
      display: none;
    }

    &-nutrient {
      padding-bottom: 0;
      margin-top: -45px;
    }
  }
}
