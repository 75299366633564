// ============================================================================
// SilverStripe Nav
// ============================================================================

.navbar-default {
  .navbar-nav {
    > .section,
    > .current,
    > .dropdown.open {
      &:after {
        content: '';
        display: none;
        position: absolute;
        bottom: -1px;
        left: 50%;
        margin-left: -4px;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 8px solid $brand-primary;
      }
    }
  }

  &.navbar-subopened {
    .navbar-nav {
      > .section:not(.open),
      > .current:not(.open) {
        &:after {
          display: none;
        }
      }
    }
  }

  .dropdown-menu {
    border-top-color: $brand-primary;
    font-size: 14px;

    a:hover,
    a:focus {
      background: rgba($color-gray,.2) !important;
      color: $navbar-default-link-color !important;
    }

    .current a {
      background: $brand-primary !important;
      color: #fff !important;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .navbar-default {
    .navbar-nav {
      .current {
        > a {
          &,
          &:hover,
          &:focus {
            background: $brand-primary;
            color: #fff !important;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .navbar-right .dropdown:first-child .dropdown-menu {
    left: 0;
    right: auto;
  }

  .navbar-default {
    .navbar-nav {
      > .section,
      > .current,
      > .dropdown.open {
        &:after {
          display: block;
        }
      }
    }
  }
}
